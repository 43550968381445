@use "mixin" as mixin;
@use "config/variables" as v;

.recruit {
  &__mv {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__information {
    margin-top: 72px;
    @include mixin.sp {
      margin-top: 28px;
    }
    &-heading {
      text-align: center;
    }
    &-intro {
      --min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
      line-height: 1.8;
      margin-top: 53px;
      @include mixin.sp {
        margin-top: 39px;
      }
    }
    &-paragraph {
      content: "";
      display: block;
      line-height: 28px;
      @include mixin.sp {
        display: none;
      }
    }
    &-list {
      padding-top: 65px;
      @include mixin.sp {
        padding-top: 53px;
      }
    }
    &-items {
      padding-top: 28px;
    }
    &-item {
      --min-size: 15;
      --max-size: 20;
      font-size: var(--clamp-size);
      line-height: 1;
      text-decoration: underline;
      &:not(:first-child) {
        padding-top: 20px;
      }
    }
  }
  &__guideline {
    &-items {
      margin-top: 130px;
      margin-bottom: 147px;
      @include mixin.sp {
        margin-top: 59px;
      }
    }
    &-item {
      &:not(:first-child) {
        margin-top: 80px;
      }
    }
    &-heading {
      margin-left: 21px;
      @include mixin.sp {
        margin-left: 7px;
      }
    }
    &-contents {
      border-collapse: separate;
      border-spacing: 1px;
      width: 100%;
      margin-top: 20px;
      @include mixin.sp {
        margin-top: 20px;
      }
    }
    &-content {
      vertical-align: middle;
      &:not(:first-child) th {
        @include mixin.sp {
          padding-top: 26px;
        }
      }
    }
    &-title {
      width: 18%;
      --min-size: 15;
      --max-size: 20;
      font-size: var(--clamp-size);
      font-weight: 500;
      padding-top: 35px;
      padding-bottom: 30px;
      padding-left: 21px;
      text-align: left;
      vertical-align: middle;
      border-bottom: 2px dotted #808080;
      @include mixin.sp {
        width: 100%;
        display: block;
        padding-top: initial;
        padding-bottom: initial;
        padding-left: 8px;
        border-bottom-width: 0;
      }
      &--vertical-top {
        vertical-align: top;
        line-height: 1;
      }
    }
    &-text {
      --min-size: 15;
      --max-size: 20;
      font-size: var(--clamp-size);
      line-height: calc(32 / 20);
      padding-top: 35px;
      padding-bottom: 30px;
      vertical-align: middle;
      border-bottom: 2px dotted #808080;
      @include mixin.sp {
        display: block;
        padding: 16px 0 21px 13px;
      }
      &-list {
        line-height: 1;
        &:not(:first-child) {
          padding-top: 16px;
        }
      }
    }
    &-btn {
      @include mixin.sp {
        margin-top: 76px;
      }
    }
  }
}
