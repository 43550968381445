@use "mixin" as mixin;
@use "config/variables" as v;

.recruit-form {
  &__mv {
    height: 317px;
    background: url(../img/recruit-form-mv-pc.jpg) no-repeat center center / cover;
    width: 100%;
    @include mixin.sp {
      background: url(../img/recruit-form-mv-sp.jpg) no-repeat center center / cover;
      height: 176px;
    }
  }
  &__form {
    margin: 50px 0 130px;
    @include mixin.sp {
      margin: 80px 0;
    }
    &-heading03 {
      margin-top: 50px;
      text-align: center;
    }
    &-annotation {
      text-align: center;
      margin-top: 50px;
    }
    &-red {
      color: #ff0000;
    }
    &-area {
      margin: 35px auto 0;
      max-width: 700px;
      width: 100%;
    }
    &-item {
      margin: 0 auto;
      display: flex;
      margin-top: 30px;
      &:first-of-type {
        margin-top: 0;
      }
      @include mixin.sp {
        display: block;
      }
    }
    &-label {
      text-align: right;
      margin: auto 35px auto 0;
      max-width: 200px;
      width: 100%;
      @include mixin.sp {
        margin-right: 0;
        display: block;
        text-align: left;
        margin-bottom: 10px;
      }
    }
    &-radio {
    }
    .mwform-radio-field {
      display: block;
      margin-left: 10px;
    }
    &-content {
      max-width: 477px;
      width: 100%;
      overflow-wrap: anywhere;
    }
    &-box {
      border: 1px solid #000;
      max-width: 477px;
      width: 100%;
      padding: 13px;
    }
    &-privacy {
      margin-top: 70px;
      display: block;
      text-align: center;
      text-decoration: underline;
      --min-size: 16;
      --max-size: 17;
      font-size: var(--clamp-size);
      @include mixin.sp {
        margin-top: 50px;
      }
    }
    &-btn {
      text-align: center;
      margin-top: 50px;
      @include mixin.sp {
        margin-top: 40px;
      }
    }
    &-complete {
      &-content {
        --min-size: 16;
        --max-size: 17;
        font-size: var(--clamp-size);
        margin-top: 50px;
      }
      &-link {
        text-decoration: underline;
        display: inline-block;
      }
    }
  }
  .mw_wp_form_input {
    margin-top: 60px;
  }
}
