@use "mixin" as mixin;
@use "config/variables" as v;

.example {
  margin-bottom: 56px;
  &__heading {
    text-align: center;
    padding-bottom: 41px;
    border-bottom: 1px solid #808080;
    margin-top: 72px;
    @include mixin.sp {
      margin-top: 28px;
    }
  }
  &__filter {
    padding-top: 48px;
    padding-bottom: 43px;
    border-bottom: 1px solid #808080;
    height: 100%;
    @include mixin.mobile {
      padding-top: 21px;
      padding-right: 17px;
      padding-bottom: 21px;
    }
    &-inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-serch-btn {
      text-align: center;
      margin-top: 24px;
    }
    &-text {
      --min-size: 15;
      --max-size: 18;
      font-size: var(--clamp-size);
      padding-right: 72px;
      @include mixin.tablet {
        padding-right: 30px;
      }
      @include mixin.sp {
        padding-right: 62px;
      }
      @include mixin.mobile {
        padding-left: 10px;
        padding-right: 30px;
      }
    }
    &-field {
      @include mixin.sp {
        display: flex;
        flex-direction: column;
      }
    }
    &-radio {
      border: 1px solid #808080;
      padding: 17px 83px 17px 4px;
      display: inline-block;
      @include mixin.pc-small {
        padding-right: 45px;
      }
      @include mixin.tablet {
        padding-right: 10px;
      }
      @include mixin.sp {
        padding-right: 83px;
      }
      @include mixin.mobile {
        display: flex;
        padding-right: 50px;
      }
      &:not(:first-child) {
        @include mixin.sp {
          margin-top: 15px;
        }
      }
      &:not(:last-child) {
        margin-right: 16px;
        @include mixin.sp {
          margin-right: initial;
        }
      }
      input[type="radio"] {
        opacity: 0;
        position: relative;
        z-index: -1;
        + span {
          --min-size: 15;
          --max-size: 18;
          font-size: var(--clamp-size);
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          position: relative;
          padding-left: 20px;
          @include mixin.mobile {
            word-break: keep-all;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            line-height: 1;
            border: 1px solid #000;
          }
          &::after {
            content: "";
            display: none;
          }
        }
        &:checked + span::after {
          display: block;
          position: absolute;
          top: 4px;
          left: 4px;
          width: 10px;
          height: 10px;
          line-height: 1;
          border-radius: 50%;
          background-color: #1e90ff;
        }
      }
    }
  }
  &__cards {
    margin-top: 56px;
    margin-top: 56px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 33px 54px;
    margin-bottom: 91px;
    margin-bottom: 91px;
    @include mixin.sp {
      margin-top: 35px;
      grid-template-columns: 1fr 1fr;
      gap: 33px 27px;
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    &-img {
      position: relative;
      width: 100%;
      &::before {
        content: "";
        display: block;
        padding-top: 63.5922897%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 17px 0 17px 18px;
      @include mixin.sp {
        padding: 10px;
      }
    }
    &-category {
      --min-size: 13;
      --max-size: 13;
      font-size: var(--clamp-size);
      color: #448350;
    }
    &-title {
      flex-grow: 1;
      --min-size: 14;
      --max-size: 17;
      font-size: var(--clamp-size);
      padding-top: 14px;
    }
  }
  &__item {
    padding-top: 56px;
    &-explain {
      padding: 28px 32px;
      margin-top: 58px;
      border: 1px solid #8c8c8c;
      &-ttl {
        --min-size: 24;
        --max-size: 32;
        font-size: var(--clamp-size);
        text-align: center;
      }
      &-cat {
        color: #448350;
        --min-size: 18;
        --max-size: 20;
        font-size: var(--clamp-size);
        text-align: center;
      }
      &-subttl {
        --min-size: 20;
        --max-size: 22;
        font-size: var(--clamp-size);
        text-align: center;
        display: block;
      }
      &-txt {
        --min-size: 15;
        --max-size: 16;
        font-size: var(--clamp-size);
        line-height: calc(28 / 16);
      }
    }

    &-content {
      margin-top: 36px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include mixin.tablet {
        flex-direction: column;
      }
      &-main {
        width: 63.2%;
        position: relative;
        overflow: hidden;
        @include mixin.tablet {
          width: 100%;
        }
        &-img {
          height: 514px;
          object-fit: cover;
          @include mixin.sp {
            height: 274px;
          }
        }
        &-ttl {
          height: 32px;
          --min-size: 16;
          --max-size: 18;
          font-size: var(--clamp-size);
          position: relative;
          margin-top: 10px;
        }

        .slick {
          //slickのjsにて自動生成されるクラス　←jsにて自動生成されるため親の中にクラス直接指定
          &-track {
            width: 100% !important;
            height: 100%;
            transform: none !important;
          }
          &-main {
            width: 100% !important;
            cursor: pointer;
            height: 100%;
            display: flex;
            position: relative;
            overflow: hidden;
          }
          &-slide {
            height: 0;
            display: none;
            &.slick-current {
              height: 100%;
              display: block;
            }
            img {
              display: block;
              width: 100%;
              aspect-ratio: 4 / 2.6;
              object-fit: cover;
            }
          }
        }
        button {
          //slickのjsにて自動生成されるボタン　←jsにて自動生成されるため親の中にクラス直接指定
          &.slick {
            //slickのjsにて自動生成されるボタンのクラス名
            &-prev {
              background-image: url("../img/prev--r.png") !important;
              content: none;
              background-color: rgba(255, 255, 255, 0.8);
              background-position: center left 20px;
              position: absolute;
              left: 0;
              &.slick-arrow {
                width: 50px;
                height: 100px;
                position: absolute;
                margin: auto;
                z-index: 2000;
                // background-color: transparent;
                border: none;
                cursor: pointer;
                outline: 0;
                padding: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                font-size: 0;
                -webkit-box-shadow: 0 0 8px rgb(0 0 0 / 15%);
                box-shadow: 0 0 8px rgb(0 0 0 / 15%);
                transition: all 0.2s ease;
                -webkit-transition: all 0.2s ease;
                background-repeat: no-repeat;
                background-size: 20px;
                top: 50%;
                transform: translateY(calc(-50% - 32px));
              }
            }
            &-next {
              background-image: url("../img/next--r.png") !important;
              content: none;
              background-color: rgba(255, 255, 255, 0.8);
              background-position: center right 20px;
              position: absolute;
              right: 0;
              &.slick-arrow {
                width: 50px;
                height: 100px;
                position: absolute;
                margin: auto;
                z-index: 2000;
                // background-color: transparent;
                border: none;
                cursor: pointer;
                outline: 0;
                padding: 0;
                background-color: rgba(255, 255, 255, 0.8);
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                font-size: 0;
                -webkit-box-shadow: 0 0 8px rgb(0 0 0 / 15%);
                box-shadow: 0 0 8px rgb(0 0 0 / 15%);
                transition: all 0.2s ease;
                -webkit-transition: all 0.2s ease;
                background-repeat: no-repeat;
                background-size: 20px;
                top: 50%;
                transform: translateY(calc(-50% - 32px));
              }
            }
          }
        }
      }
      &-nav {
        width: 34.3%;
        @include mixin.tablet {
          width: 100%;
        }
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .slick-list {
          //slickのjsにて自動生成されるクラス　←jsにて自動生成されるため親の中にクラス直接指定
          width: 100%;
          .draggable {
            height: 100% !important;
          }
          .slick {
            &-track {
              width: 100% !important;
              height: 100%;
              transform: none !important;
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 12px;
              @include mixin.tablet {
                margin-top: 48px;
              }
              @include mixin.sp {
                margin-top: 43px;
              }
            }
            &-current {
              display: block;
            }
            &-current {
              display: block;
            }
            &-slide {
              width: 100% !important;
              cursor: pointer;
              border: 1px solid #ccc;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1 !important;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(#232323, 0.8);
                z-index: 1001;
              }
              &.slick-current {
                &::before {
                  content: none;
                }
              }
              @include mixin.tablet {
                display: initial;
              }
              img {
                max-width: 100%;
                width: 100%;
                aspect-ratio: 4 / 2.6;
                object-fit: cover;
              }
              div {
                div {
                  display: initial !important;
                }
              }
            }
          }
        }
      }
      &-item {
        @include mixin.tablet {
          height: 230px;
          object-fit: cover;
          width: 100%;
          @include mixin.sp {
            height: 115px;
          }
        }
        &-img {
          max-width: 100%;
          width: 100%;
          position: relative;
        }
        &-ttl {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% + 2px));
          z-index: 2000;
          color: #fff;
          --min-size: 15;
          --max-size: 15;
          font-size: var(--clamp-size);
        }
      }
      &-link {
        margin-top: 110px;
        margin-bottom: 128px;
        text-align: center;
      }
    }
    &-relation {
      margin-top: 84px;
      &-ttl {
        text-align: center;
        --min-size: 22;
        --max-size: 22;
        font-size: var(--clamp-size);
      }
      &-items {
        display: flex;
        margin: 16px auto 0;
        text-align: center;
        justify-content: center;
        @include mixin.sp {
          flex-wrap: wrap;
        }
      }
      &-item {
        width: calc((100% - 18px) / 4);
        margin-top: 16px;
        @include mixin.sp {
          width: calc((100% - 6px) / 2);
        }
        &:not(:first-child) {
          margin-left: 6px;
          @include mixin.sp {
            margin-left: 0;
          }
        }
        &:nth-child(2n) {
          @include mixin.sp {
            margin-left: 6px;
          }
        }
        &-link {
          width: 100%;
          display: block;
        }
        &-ttl {
          --min-size: 10;
          --max-size: 16;
          font-size: var(--clamp-size);
          margin-top: 10px;
        }
      }
    }
  }
}
