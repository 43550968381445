@use "mixin" as mixin;
@use "config/variables" as v;

.dojou {
	&__heading03 {
		--min-size: 20;
		--max-size: 30;
		font-size: var(--clamp-size);
		font-weight: bold;
		text-align: center;
	}
  &__mv {
	  height: 100%;
	  width: 100%;
	  object-fit: cover;
    }
  &__intro {
    &-heading { 
			margin-top: 72px;
			@include mixin.sp {
				margin-top: 28px;
			}
		}
    &-content {
      --min-size: 15;
      --max-size: 18;
      font-size: var(--clamp-size);
      line-height: calc(26 / 15);
      padding-top: 87px;
			text-align: center;
      @include mixin.sp {
        padding-top: 33.6px;
				text-align: left;
      }
    }
		&-image{
			display: block;
			margin: 0 auto;
			margin-top: 40px;
			max-width: 589px;
			width: 100%;
		}
  }
  &__point {
		margin-top: 33px;
		&-image{
			max-width: 558px;
			margin: 0 auto;
			display: block;
			margin-top: 35px;
		}
  }
  &__recycle {
    margin-top: 87px;
    @include mixin.sp {
      margin-top: 60px;
    }
    &-heading {
      text-align: center;
    }
    &-content {
      --min-size: 15;
      --max-size: 18;
      font-size: var(--clamp-size);
      line-height: calc(26 / 15);
      padding-top: 20px;
			max-width: 810px;
			margin: 0 auto;
    }
		&-image{
			margin-top: 43px;
			display: block;
			max-width: 810px;
			margin: 0 auto;
		}
  }
	&__regeneration{
		margin-top: 117px;
		&-items{
			margin-top: 43px;
		}
		&-item{
			display: flex;
			margin-bottom: 40.5px;
			&:last-of-type{
				margin-bottom: 0;
			}
			@include mixin.sp {
        display: block;
      }
		}
		&-image{
			max-width: 408px;
			width: 100%;
			img {
				height: auto;
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
		}
		&-content{
			background: #f2f2f2;
			padding: 35.5px 61.5px 35.5px;
			width: 100%;
			@include mixin.pc-small {
				padding: 35.5px 40.5px 35.5px;
			}
			@include mixin.tablet {
				padding: 35.5px 30.5px 35.5px;
			}
			@include mixin.sp {
        padding: 19px 24px;
      }
			&-title{
				font-weight: bold;
				--min-size: 20;
				--max-size: 22;
				font-size: var(--clamp-size);
			}
			&-machine{
				font-weight: bold;
				--min-size: 15;
				--max-size: 18;
				font-size: var(--clamp-size);
				margin-top: 20px;
			}
			&-desc{
				--min-size: 15;
				--max-size: 18;
				font-size: var(--clamp-size);
				margin-top: 10px;
			}
		}
	}
	&__solidification{
		margin-top: 120px;
		&-items{
			margin-top: 43px;
		}
		&-item{
			display: flex;
			@include mixin.sp {
        display: block;
      }
		}
		&-image{
			max-width: 408px;
			width: 100%;
			img {
				height: auto;
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
		}
		&-content{
			background: #f2f2f2;
			padding: 35.5px 61.5px 35.5px;
			width: 100%;
			@include mixin.pc-small {
				padding: 35.5px 40.5px 35.5px;
      }
			@include mixin.tablet {
				padding: 35.5px 30.5px 35.5px;
			}
			@include mixin.sp {
        padding: 19px 24px;
      }
			&-title{
				font-weight: bold;
			}
			&-lead{
				font-weight: bold;
				--min-size: 16;
				--max-size: 19;
				font-size: var(--clamp-size);
				margin-top: 20px;
				margin-top: 30px;
				&:first-of-type{
					margin-top: 15px;
				}
			}
			&-desc{
				--min-size: 15;
				--max-size: 17;
				font-size: var(--clamp-size);
				margin-top: 10px;
			}
			&-link{
				text-decoration: underline;
			}
		}
	}
	&__affiliated{
		margin-top: 117px;
		margin-bottom: 112px; 
		@include mixin.sp {
			margin-bottom: 56px;
		}
		&-items{
			margin-top: 43px;
		}
		&-item{
			display: flex;
			margin-bottom: 44.5px;
			&:last-of-type{
				margin-bottom: 0;
			}
			@include mixin.sp {
        display: block;
      }
		}
		&-image{
			max-width: 408px;
			width: 100%;
			img {
				height: auto;
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
		}
		&-content{
			background: #f2f2f2;
			padding: 35.5px 61.5px 35.5px;
			width: 100%;
			@include mixin.pc-small {
				padding: 35.5px 40.5px 35.5px;
      }
			@include mixin.tablet {
				padding: 35.5px 30.5px 35.5px;
      }
			@include mixin.sp {
        padding: 18px 15px;
      }
			&-title{
				font-weight: bold;
				--min-size: 20;
				--max-size: 22;
				font-size: var(--clamp-size);
			}
			&-logo{
				display: inline-block;
				max-width: 137px;
				width: 100%;
				padding-top: 10px;
				margin-left: 20px;
				@include mixin.pc-small {
					margin-left: 0;
					display: block;
				}
			}
			&-lead{
				font-weight: bold;
				--min-size: 15;
				--max-size: 18;
				font-size: var(--clamp-size);
				margin-top: 20px;
			}
			&-desc{
				--min-size: 15;
				--max-size: 18;
				font-size: var(--clamp-size);
				margin-top: 10px;
			}
		}
	}
}
