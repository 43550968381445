@use "config/variables" as v;
/*---------------------------------------------------------
BrakePoint
----------------------------------------------------------*/
@mixin pc {
  @media (max-width: (v.$pc)) {
    @content;
  }
}

@mixin pc-small {
  @media (max-width: (v.$pc-small)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (v.$tablet)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: (v.$sp)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (v.$mobile)) {
    @content;
  }
}
