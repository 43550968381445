@use "mixin" as mixin;
@use "config/variables" as variables;

.header {
  height: 120px;
  background: #fff;
  @include mixin.tablet {
    height: 60px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__logo {
    width: 257px;
    height: auto;
    @include mixin.sp {
      width: 184px;
    }
  }
  &__inner {
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    max-width: 1368px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    @include mixin.tablet {
      flex-direction: row;
      align-items: center;
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
    @include mixin.tablet {
      margin-top: 0;
    }
    p {
      --min-size: 12;
      --max-size: 12;
      font-size: var(--clamp-size);
      @include mixin.tablet {
        display: none;
      }
    }
  }

  &__contents {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // @include mixin.tablet {
    //     display:none;
    // }
  }
  &__nav {
    @include mixin.tablet {
      position: fixed;
      top: 80px;
      left: -100%;
      display: flex;
      flex-direction: column;
    }
    &.open-toggle {
      top: 60px;
      left: 0;
      width: 100%;
      background: #fff;
    }
  }
  &__nav-lists {
    display: flex;
    @include mixin.tablet {
      flex-direction: column;
    }
  }
  &__nav-list {
    position: relative;
    a {
      padding: 15px calc((32px / 1368px) * 100vw);
      line-height: 1;
      display: block;
      --min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
    }
  }
  &__dropdown {
    &-lists {
      width: 100%;
      position: absolute;
      top: 40px;
      left: 0;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;
    }

    &-list {
      border: 0.5px solid #000;
      &:not(:first-child) {
        border-top-width: 0;
      }
      a {
        --min-size: 10;
        --max-size: 15;
        font-size: var(--clamp-size);
        text-align: center;
        background-color: #fff;
        padding: 10px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &__btn-contact {
    background: #448350;
    color: #fff;
    padding: 14px 18px;
    max-width: 190px;
    width: 100%;
    display: inline-block;
    line-height: 1;
    --min-size: 15;
    --max-size: 16;
    font-size: var(--clamp-size);
    text-align: center;
    margin-bottom: 17px;
    @include mixin.tablet {
      display: none;
    }
    span {
      position: relative;
      margin-left: 10px;
      &::before {
        position: absolute;
        content: "";
        background: url("../img/email-icon.png") no-repeat center;
        height: 20px;
        width: 27px;
        top: 50%;
        background-size: cover;
        left: -35px;
        transform: translateY(-50%);
      }
      &::after {
        position: absolute;
        content: "›";
        top: 50%;
        background-size: cover;
        right: -20px;
        transform: translateY(-50%);
      }
    }
  }

  &__btn-toggle {
    display: none;
    @include mixin.tablet {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 30px;
      height: 30px;
    }
    span {
      &:nth-child(1) {
        transform: translateY(-12px);
        background: #000;
        height: 1px;
        width: 100%;
        transition: all 0.3s;
      }
      &:nth-child(2) {
        background: #000;
        height: 1px;
        width: 100%;
        transition: all 0.3s;
      }
      &:nth-child(3) {
        transform: translateY(12px);
        background: #000;
        height: 1px;
        width: 100%;
        transition: all 0.3s;
      }
    }
    &.open-toggle {
      span {
        &:nth-child(1) {
          transform: translateY(1px) rotate(45deg);
          background: #000;
          height: 1px;
          width: 100%;
          transition: all 0.3s;
        }
        &:nth-child(2) {
          background: #000;
          height: 1px;
          width: 100%;
          transform: translateX(50px);
          transition: all 0.3s;
        }
        &:nth-child(3) {
          transform: translateY(-1px) rotate(-45deg);
          background: #000;
          height: 1px;
          transition: all 0.3s;
        }
      }
    }
  }
}
