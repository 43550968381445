@use "mixin" as mixin;
@use "config/variables" as v;

.contact{
	&__mv {
    height: 100%;
    background: url(../img/contact-mv-pc.jpg) no-repeat center center / cover;
    width: 100%;
	object-fit: cover;
    @include mixin.sp {
      background: url(../img/contact-mv-sp.jpg) no-repeat center center / cover;
      height: 176px;
    }
  }
	&__intro{
		margin-top: 50px;
		&-heading03{
			margin-top: 60px;
		}
		&-content{
			margin-top: 17.5px;
			text-align: center;
			max-width: 690px;
			width: 100%;
			margin: 0 auto;
			padding: 25px;
		}
		&-tel{
			--min-size: 20;
      --max-size: 28;
      font-size: var(--clamp-size);
		}
		&-fax{
			--min-size: 20;
      --max-size: 28;
      font-size: var(--clamp-size);
		}
		&-hours{
			--min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
		}
	}
	&__form{
		margin: 100px 0;
		@include mixin.sp{
			margin: 60px 0;
		}
		&-area{
			margin: 35px auto 0;
			max-width: 700px;
			width: 100%;
		}
		&-item{
			margin: 0 auto;
			display: flex;
			margin-top: 30px;
			&:first-of-type{
				margin-top: 0;
			}
			@include mixin.sp{
				display: block;
			}
		}
		&-label{
			text-align: right;
			margin: auto 35px auto 0;
			max-width: 200px;
			width: 100%;
			@include mixin.sp{
				margin-right: 0;
				display: block;
				text-align: left;
				margin-bottom: 10px;
			}
		}
		&-content{
			max-width: 477px;
			width: 100%;
			overflow-wrap: anywhere;
		}
		&-box{
			border: 1px solid #000;
			max-width: 477px;
			width: 100%;
			padding: 13px;
		}
		&-privacy{
			margin-top: 70px;
			display: block;
			text-align: center;
			text-decoration: underline;
			--min-size: 16;
      --max-size: 17;
      font-size: var(--clamp-size);
			@include mixin.sp{
				margin-top: 50px;
			}
		}
		&-btn{
			text-align: center;
			margin-top: 50px;
			@include mixin.sp{
				margin-top: 40px;
			}
		}
		&-complete{
			&-content{
				--min-size: 16;
				--max-size: 17;
				font-size: var(--clamp-size);
			}
			&-link{
				text-decoration: underline;
				display: inline-block;
			}
		}
	}
}
.mw_wp_form_complete{
	text-align: center;
	@include mixin.sp{
		text-align: left;
	}
}