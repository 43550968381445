@use "mixin" as mixin;
@use "config/variables" as variables;

html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", 
  "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", 
  "Yu Gothic", Verdana, Meiryo, sans-serif;
  line-height: 1.75;
  --min-size: 16;
  --max-size: 17;
  font-size: var(--clamp-size);
  color: #231815;
  font-weight: 500;
  background: #fff;
}

img {
  width: 100%;
  height: auto;
}

a {
  transition: .3s;
  &:hover {
    opacity: .8;
    transition: .3s;
  }
}

.background__cover {
  transition: all .3s;
  &.open-toggle {
  position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(3,3,3,.5);
	display: block;
  transition: all .3s;
  }
}