@use "mixin" as mixin;
@use "config/variables" as v;

.doboku {
  &__mv {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__intro {
    &-heading {
      margin-top: 72px;
      @include mixin.sp {
        margin-top: 28px;
      }
    }
    &-content {
      --min-size: 16;
      --max-size: 18;
      font-size: var(--clamp-size);
      line-height: calc(26 / 15);
      text-align: center;
      padding-top: 30px;
      @include mixin.sp {
        padding-top: 28px;
        text-align: inherit;
      }
    }
  }
  &__slide {
    margin-top: 40px;
    .swiper--wrapper {
      width: 100%;
      height: 300px;
    }
    .swiper-slide {
      width: 25%;
      height: 100%;
      max-height: 238px;
      @include mixin.sp {
        width: 40%;
      }
      .swiper-slide-img {
        height: 100%;
        max-height: 238px;
      }
    }
  }
  &__construction {
    margin-top: 66px;
    &-items {
      display: grid;
      gap: 50px 15px;
      grid-template-columns: 1fr 1fr 1fr;
      @include mixin.sp {
        grid-template-columns: 1fr 1fr;
        gap: 40px 8px;
      }
    }
    &-item {
      &-title {
        text-align: center;
        --min-size: 16;
        --max-size: 22;
        font-size: var(--clamp-size);
        font-weight: bold;
        margin-top: 10px;
      }
      &-detail {
        text-align: center;
        --min-size: 13;
        --max-size: 17;
        font-size: var(--clamp-size);
        margin-top: 10px;
        @include mixin.sp {
          margin-top: 5px;
        }
      }
      &-image {
        display: block;
      }
    }
  }
  &__regeneration {
    margin-top: 200px;
    @include mixin.sp {
      margin-top: 70px;
    }
    &-content {
      --min-size: 16;
      --max-size: 18;
      font-size: var(--clamp-size);
      line-height: 1.7333333333;
      padding-top: 30px;
      text-align: center;
      max-width: 910px;
      width: 100%;
      margin: 0 auto;
      @include mixin.sp {
        text-align: left;
      }
    }
    &-image {
      max-width: 810px;
      width: 100%;
      margin: 0 auto;
      display: block;
      margin-top: 40px;
      @include mixin.sp {
        margin-top: 30px;
      }
    }
  }
  &__example {
    margin-top: 97px;
    padding-bottom: 90px;
    @include mixin.sp {
      margin-top: 60px;
    }
    &-heading {
      padding-top: 72px;
      @include mixin.sp {
        padding-top: 40px;
      }
    }
    &-items {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 0 auto;
      margin-top: 58px;
      max-width: 1085px;
      width: 100%;
      @include mixin.sp {
        grid-template-columns: 1fr 1fr;
      }
    }
    &-item {
      height: 100%;
      background: #fff;
      position: relative;
      &-anchor {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &-category {
        color: #448350;
        --min-size: 10;
        --max-size: 13;
        font-size: var(--clamp-size);
        margin: 15px 13px 8px;
        display: inline-block;
        position: relative;
      }
      &-title {
        --min-size: 12;
        --max-size: 17;
        font-size: var(--clamp-size);
        padding: 0 13px 20px;
      }
      &-image {
        display: block;
      }
    }
    &-btn {
      display: block;
      margin: 0 auto;
      margin-top: 71px;
    }
  }
}
