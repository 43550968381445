@use "mixin" as mixin;

#page_top {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    position: fixed;
    right: 5px;
    bottom: 62px;
    background: rgba(rgb(68, 131, 80) ,.9);
    padding: 0.3rem 0.7rem 0 0.4rem;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  #page_top a {
    // position: relative;
    display: block;
    width: 80px;
    height: 40px;
    text-decoration: none;
    border: none;
    z-index: 50;
  }
  #page_top a::before {
    content: '▲';
    font-size: 1.2rem;
    color: #fff;
    position: absolute;
    margin: auto;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  
    /*topへ戻る*/
    @media print, screen and (min-width: 767px) {
      #page_top {
        width: 90px;
        height: 40px;
        position: fixed;
        right: 4px;
        bottom: 62px;
        background: rgb(123 123 123 / 50%);
        padding: 0.3rem 0.7rem 0 0.4rem;
        opacity: 0.7;
        border-radius: 0;
      }
      #page_top a::before {
        content: '▲ TOP';
        font-size: 0.9rem;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 40px;
        transform: translate(-50%, calc(-50% + 2px));
        margin: auto;
        text-align: center;
      }
      #page_top a {
        position: relative;
        // display: block;
        // width: 80px;
        // height: 40px;
        // text-decoration: none;
        border: none;
        display: flex;
        align-items: center;
        z-index: 50;
      }
    }

    body.fixed {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
    }
    
    .modal {
      display: none;
      height: 100vh;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
    }

    .bottom-contact-btn {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 99;
      display: none;
      height: 55px;
    }
    
    .bottom-contact-btn .c-btn-contact a {
      font-size: 1rem;
    }
    
    @media print, screen and (min-width: 320px) {
      .bottom-contact-btn .c-btn-contact a {
        padding: 16px 3px 15px 0;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    @media print, screen and (min-width: 500px) {
      .bottom-contact-btn .c-btn-contact a .mail-contact__icon {
        width: 24px;
      }
    }
    
    .bottom-contact-btn .c-btn-phone a {
      font-size: 1rem;
    }
    
    @media print, screen and (min-width: 320px) {
      .bottom-contact-btn .c-btn-phone a {
        padding: 16px 3px 15px 0;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    @media print, screen and (min-width: 500px) {
      .bottom-contact-btn .c-btn-phone a {
        font-size: 1rem;
      }
    }
    
    @media print, screen and (min-width: 500px) {
      .bottom-contact-btn .c-btn-phone a .phone-contact__icon {
        width: 24px;
      }
    }
    
    .bottom-contact-btn.is-disp {
      display: flex;
    }
    
    @media print, screen and (min-width: 767px) {
      .bottom-contact-btn.is-disp {
        display: none;
      }
    }
    
    @media print, screen and (min-width: 767px) {
      .bottom-contact-btn {
        display: none;
      }
    }
    
    .bottom-contact-btn .bottom-contact-btn__item {
      width: 100%;
    }
    
    body.fixed {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
    }
   
.modal {
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

@media print, screen and (min-width: 767px) {
  .modal {
    display: none;
  }
}

.modal__bg {
  background: rgba(51, 51, 51, 0.9);
  height: 100vh;
  position: absolute;
  width: 100%;
}

.modal__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 101;
  max-width: 430px;
  margin-top: -43px;
}

.modal__content .modal__box {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  text-align: center;
  padding: 2.5em 1em;
}

.modal__content .modal__box .modal-info {
  color: #FFF;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  text-align: left;
}

.modal__content .modal__box .btn-modal-tel {
  margin: 40px auto 0;
}

.modal__content .modal__box .btn-modal-tel a {
  display: block;
  padding: 9px 13px 8px 0;
  color: #000;
  background: #FFF;
  font-weight: bold;
  font-size: 1rem;
}

.modal__content .l-modal__box .c-btn-modal-tel a .mail-contact__icon {
  width: 25px;
  height: auto;
  margin-right: 8px;
  padding-bottom: 5px;
}

.modal__content .modal__box .btn-modal-tel a span {
  padding: 0 25px;
  background: url("../img/icon-arrow-right@2x.png") 96% 50%/6px 9px no-repeat;
}

.modal__content .btn-modal-close {
  padding-top: 1em;
  color: #FFF;
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
}


.btn-contact {
  margin: auto;
}

.btn-contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 55px;
  color: #fff;
  background: #4D4D4D;
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mixin.sp {
    font-size: .9rem;
    padding: 0 5px;
  }
  @include mixin.sp {
    font-size: .8rem;
  }
}
.btn-contact a .mail-contact__icon {
  width: auto;
  height: 18px;
  margin-right: 8px;
}
.btn-contact a span {
  padding: 0 20px;
  background: url("../img/icon-arrow-right_wh.png") 96% 50%/6px 9px no-repeat;
  display: flex;
  align-items: center;
}
.btn-phone {
  margin: auto;
}
.btn-phone a {
  padding: 0 20px;
  height: 55px;
  color: #fff;
  background: #4d4d4d;
  font-weight: bold;
  font-size: 1rem;
  border-right: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mixin.sp {
    font-size: .9rem;
    padding: 0 5px;
  }
  @include mixin.sp {
    font-size: .8rem;
  }
}
.btn-phone a .phone-contact__icon {
  width: auto;
  height: 18px;
  margin-right: 8px;
  padding-bottom: 3px;
}
.btn-phone a span {
  padding: 0 20px;
  background: url("../img/icon-arrow-right_wh.png") 96% 50%/6px 9px no-repeat;
}

.nav-links {
  display:flex;
  justify-content: center;
  .page-numbers {
      height: 46px;
      width: 39px;
      margin: 50px 8px 0;
      background: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000000;
      &.current {
          background:#000000;
          color: #fff;
      }
  }
}
