/*---------------------------------------------------------
BreakPoint
----------------------------------------------------------*/
$pc: 1388px; // PC
$pc-small: 980px; // タブレット
$tablet: 768px; // タブレット
$sp: 600px; // sp
$mobile: 420px; // sp

/*---------------------------------------------------------
Color
----------------------------------------------------------*/
$cWhite: #fff;
$cBlack: #000;
$cDarkBlack: #2b2b2b;
$cGray: #c0c0c0;
$cLightGray: #eee;
$cWays: #ee723a;

/*---------------------------------------------------------
Spacer
----------------------------------------------------------*/
$spacing-1x: 4px;
$spacing-2x: 8px;
$spacing-3x: 12px;
$spacing-4x: 16px;
$spacing-5x: 20px;
$spacing-6x: 24px;
$spacing-7x: 28px;
$spacing-8x: 32px;
$spacing-10x: 40px;
$spacing-12x: 48px;
$spacing-14x: 52px;
$spacing-16x: 64px;
$spacing-18x: 72px;
$spacing-20x: 80px;
$spacing-30x: 80px;

/*---------------------------------------------------------
Line-height
----------------------------------------------------------*/
$line-height-none: 1;
$line-height-xs: 1.2;
$line-height-sm: 1.3;
$line-height-md: 1.5;
$line-height-mdx: 1.6;
$line-height-lg: 1.75;
$line-height-xl: 1.8;
$line-height-xxl: 1.8;
$line-height-xxxl: 2;

/*---------------------------------------------------------
Font-size
----------------------------------------------------------*/
$font-size-xxxs: 8px;
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 16px;
$font-size-smx: 18px;
$font-size-md: 21px;
$font-size-mdx: 26px;
$font-size-lg: 32px;
$font-size-xl: 40px;
$font-size-xxl: 60px;
$font-size-xxxl: 80px;
