@use "mixin" as mixin;
@use "config/variables" as variables;

.heading02 {
  --min-size: 22;
  --max-size: 32;
  font-size: var(--clamp-size);
  line-height: calc(32 / 22);
  position: relative;
  display: inline-block;
  padding: 0 calc(35px + 97px);
  font-weight: bold;
  @include mixin.sp {
    padding: 0 83px;
  }
  @include mixin.mobile {
    padding: 0 40px;
  }
  font-weight: bold;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 97px;
    height: 1px;
    background-color: #000;
    @include mixin.sp {
      width: 76px;
    }
    @include mixin.mobile {
      width: 33px;
    }
  }
  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
  &__outer {
    text-align: center;
  }
  &__news {
    --min-size: 28;
    --max-size: 30;
    font-size: var(--clamp-size);
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 1;
  }
}
.heading03 {
  --min-size: 22;
  --max-size: 22;
  font-size: var(--clamp-size);
  line-height: 1;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #398e4a;
  }
  &__outer {
    text-align: center;
  }
}
