@use "mixin" as mixin;
@use "config/variables" as variables;

.footer {
  --min-size: 13;
  --max-size: 16;
  font-size: var(--clamp-size);
  background: #4d4d4d;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  @include mixin.tablet {
    text-align: center;
  }
  &__nav {
    display: flex;
    &ーlists {
      display: flex;
      @include mixin.tablet {
        display: none;
      }
      a {
        padding: 0 calc((16px / 1366px) * 100vw);
      }
    }
    &ーlist {
      &::after {
        content: "/";
      }
    }
  }
  &__heading {
    margin-top: 60px;
    display: flex;
    justify-content:space-between;
    align-items:center;
    width: 260px;
    @include mixin.tablet {
      margin-top: 0;
    }
  }
  &__logo {
    --min-size: 23;
    --max-size: 26;
    max-width: 208px;
    font-size: var(--clamp-size);

    @include mixin.tablet {
      margin-top: 0;
    }
    a {
      display: flex;
      align-items: center;
    }
  }
  address {
    margin-top: 32px;
    --min-size: 14;
    --max-size: 17;
    font-size: var(--clamp-size);
    @include mixin.tablet {
      text-align: center;
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: #e7e7e7;
    color: #000000;
    width: 100%;
    text-align: center;
    padding: 19px 0 15px;
    line-height: 1;
    margin-top: 45px;
    small:nth-child(2) {
      margin-top: 10px;
    }
  }
}
