@use "mixin" as mixin;
@use "config/variables" as v;

.business-all {
    margin-bottom: 72px;
    margin-top: 72px;
		@include mixin.sp {
		  margin-top: 28px;
          margin-bottom: 28px;
		}
    &__items {
        margin-top: 42px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 45px;
        @include mixin.sp {
            gap: 50px;
            grid-template-columns: 1fr;
        }
    }

    &__item {
        &-img {

        }
        &-ttl {
            --min-size: 22;
            --max-size: 26;
            font-size: var(--clamp-size);
            margin-top: 24px;
            text-align: center;
        }
    }
}