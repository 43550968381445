
@use "mixin" as mixin;
@use "config/variables" as variables;

.news {
    margin-top: 42px;
    margin-bottom: 120px;
    @include mixin.sp {
        margin-bottom: 56px;
    }
    &__items {
        margin-top: 15px;
    }
    &__item {
        a {
            display: flex;
            padding: 33px 10px 30px 10px;
            @include mixin.sp {
                flex-direction: column;
            }
        }
        p {
            margin-left: 50px;
            @include mixin.sp {
                margin-left: 0;
            }
        }
        border-bottom: 1px solid #ccc;
    }
    &__viewAll {
        display:block;
        width: fit-content;
        margin-top: 15px;
        margin-left: auto;
        text-decoration: underline;
    }
    &__single {
        margin-top: 42px;
        margin-bottom: 120px;
        @include mixin.sp {
            margin-bottom: 56px;
        }
        time {
            margin-top: 32px;
            display:block;
        }
        &-content {
            margin-top: 40px;
        }
    }
}