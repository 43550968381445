@use "mixin" as mixin;
@use "config/variables" as variables;
@use "pages/common";

.hero {
    .swiper {
        .swiper-slide {
            object-fit: cover;
            height: auto;
            width: 100%;
            aspect-ratio:  1366 / 639;
            @include mixin.sp{
                aspect-ratio:  375 / 450;
            }
        }
    }     
}

.top {
    &__business {
        margin-top: 92px;
        &-items {
            margin-top: 42px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 45px;
            @include mixin.sp {
                gap: 50px;
                grid-template-columns: 1fr;
            }
        }

        &-item {
            &-img {

            }
            &-ttl {
                --min-size: 22;
                --max-size: 26;
                font-size: var(--clamp-size);
                margin-top: 24px;
                text-align: center;
            }
        }
    }
    &__example {
        margin-top: 138px;
        padding: 77px 0 90px;
        background: #F2F2F2;
        @include mixin.sp {
            padding: 38px 0 52px;
            margin-top: 70px;
        }
        &-lists {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;
            margin-top: 64px;
            @include mixin.sp {
                grid-template-columns: 1fr 1fr;
                gap: 32px 20px;
            }
        }
        &-list {
            background: #fff;
            &-content {
                padding: 17px 13px;
            }
            &-category {
                --min-size: 13;
                --max-size: 13;
                font-size: var(--clamp-size);
                color: #448350;
                line-height:1;
            }
            &-ttl {
                --min-size: 14;
                --max-size: 17;
                font-size: var(--clamp-size);
                margin-top: 12px;
            }
        }
    }
    &__news {
        padding-top: 110px;
        margin-bottom: 70px;
        @include mixin.sp {
            padding-top: 50px;
        }
        &-lists {
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            &-link {
                text-align: right;
                margin-top: 16px;
            }
        }
        &-list {
            padding: 28px 18px;
            border-bottom: 2px dotted #808080;
            time {
                --min-size: 17;
                --max-size: 20;
                font-size: var(--clamp-size);
                line-height: 1.6;
            }
            h3 {
                --min-size: 17;
                --max-size: 20;
                line-height: 1.6;
                font-size: var(--clamp-size);
            }
        }
    }
    &__company {
        padding: 92px 0 66px;
        background: #F2F2F2;
        &-items {
            margin-top: 68px;
            display: grid;
            gap: 55px;
            grid-template-columns: 1fr 1fr;
            @include mixin.sp {
                grid-template-columns: 1fr;
            }
        }
        &-item {
            &-ttl {
                background: #FFFFFF;
                padding: 21px 0 17px;
                line-height: 1;
                text-align: center;
            }
        }
    }
}