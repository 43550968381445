@use "mixin" as mixin;
@use "config/variables" as v;

.architecture {
	&__mv {
	height: 100%;
    width: 100%;
    object-fit: cover;
  	}
	&__intro {
    &-heading {
		margin-top: 72px;
		@include mixin.sp {
		  margin-top: 28px;
		}
    }
    &-lead {
      --min-size: 18;
      --max-size: 24;
      font-size: var(--clamp-size);
			font-weight: bold;
			text-align: center;
			margin-top: 38px;
			margin-bottom: 30px;
		}
    &-content {
      --min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
      line-height: calc(26 / 15);
			text-align: center;
			&:last-of-type{
				margin-top: 20px;
			}
      @include mixin.sp {
				text-align: inherit;
				display: inline;
      }
    }
  }
	&__feature{
		margin-top: 120px;
		@include mixin.sp {
			&-heading02{
				padding: 0 40px;
				&::before, &::after{
					width: 35px;
				}
			}
		}
		&-items{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 21.5px;
			margin-top: 36px;
			@include mixin.pc-small {
        grid-template-columns: 1fr 1fr;
      }
			@include mixin.sp {
        grid-template-columns: 1fr;
				gap: 50px;
      }
		}
		&-item{
			&-title{
				--min-size: 17;
				--max-size: 17;
				font-size: var(--clamp-size);
				font-weight: bold;
			}
			&-image{
				margin-top: 9px;
				display: block;
			}
			&-detail{
				--min-size: 16;
				--max-size: 16;
				font-size: var(--clamp-size);
				margin-top: 9px;
			}
		}
	}
	&__service{
		margin-top: 100px;
		@include mixin.sp{
			&-heading02{
				padding: 0 60px;
				&::before, &::after{
					width: 50px;
				}
			}
		}
		&-items{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 21.5px;
			margin-top: 36px;
			@include mixin.pc-small {
        grid-template-columns: 1fr 1fr;
      }
			@include mixin.sp {
        grid-template-columns: 1fr;
				gap: 50px;
      }
		}
		&-item{
			&-title{
				--min-size: 17;
				--max-size: 17;
				font-size: var(--clamp-size);
				font-weight: bold;
			}
			&-image{
				margin-top: 9px;
				display: block;
			}
			&-detail{
				--min-size: 16;
				--max-size: 16;
				font-size: var(--clamp-size);
				margin-top: 9px;
			}
		}
	}
	&__swiper{
		width: 100%;
	}
	&__warehouse{
		margin-top: 100px;
		&-inner{
			display: flex;
			align-items: center;
			@include mixin.pc-small {
        display: block;
      }
		}
		&-content{
			padding: 8px 37px;
			width: 100%;
			@include mixin.sp {
				padding: 0;
        padding-top: 30px;
      }
		}
		&-lead{
			--min-size: 20;
			--max-size: 24;
			font-size: var(--clamp-size);
			font-weight: bold;
		}
		&-desc{
			--min-size: 16;
			--max-size: 16;
			font-size: var(--clamp-size);
			margin-top: 17px;
		}
		&-detail{
			margin-top: 20px;
			padding: 20px 0;
			border-top: 1px solid #000;
			border-bottom: 1px solid #000;
		}
	}

	&__type{
		margin-top: 156px;
		&-items{
			margin-top: 45px;
		}
		&-item{
			display: flex;
			margin-bottom: 38px;
			&:nth-child(odd){
				flex-direction: row-reverse;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			@include mixin.sp {
        display: block;
      }
		}
		&-image{
			width: 100%;
			max-width:568px ;
			@include mixin.sp {
        width: 100%;}
		img{
			width:100%;
			height:100%;
			object-fit: cover;
		}
      
		}
		&-content{
			width: 50%;
			padding: 55px;
			background: #f2f2f2;
			@include mixin.pc-small {
				padding: 40px;
      }
			@include mixin.tablet {
				padding: 20px;
      }
			@include mixin.sp {
        width: 100%;
				padding: 25px 30px;
      }
			&-title{
				font-weight: bold;
				--min-size: 20;
				--max-size: 22;
				font-size: var(--clamp-size);
				max-width: 320px;
			}
			&-desc{
				--min-size: 16;
				--max-size: 16;
				font-size: var(--clamp-size);
				border-top: 1px solid #000;
				border-bottom: 1px solid #000;
				padding: 8.5px 0;
				max-width: 320px;
				&:first-of-type{
					margin-top: 10px;
					border-bottom: none;
				}
				&:last-of-type{
					border-top: none;
				}
			}
		}
	}

	&__vinyl{
		margin-top: 120px;
		&-heading {
      padding-top: 55px;
      @include mixin.sp {
        padding-top: 28px;
      }
    }
		@include mixin.sp{
			&-heading02{
				padding: 0 60px;
				&::before, &::after{
					width: 50px;
				}
			}
		}
    &-content {
      --min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
      line-height: calc(26 / 15);
			text-align: center;
      @include mixin.sp {
				text-align: inherit;
      }
    }
		&-item{
			display: flex;
			margin-top: 45px;
			&-image{
				width: 50%;
			}
		}
	}

	&__example{
		margin-top: 97px;
		padding-bottom: 90px;
		@include mixin.sp {
			margin-top: 60px;
		}
		&-heading{
			padding-top: 72px;
			@include mixin.sp {
				padding-top: 40px;
			}
		}
		&-items{
			display: grid;
			gap: 20px;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			margin: 0 auto;
			margin-top: 58px;
			max-width: 1085px;
			width: 100%;
			@include mixin.pc-small {
        grid-template-columns: 1fr 1fr;
      }
			@include mixin.sp {
				grid-template-columns: 1fr 1fr;
			}
		}
		&-item{
			height: 100%;
			background: #fff;
			position: relative;
			&-anchor{
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
			&-category{
				color: #448350;
				--min-size: 10;
				--max-size: 13;
				font-size: var(--clamp-size);
				margin: 15px 13px 8px;
				display: inline-block;
				position: relative;

			}
			&-title{
				--min-size: 12;
				--max-size: 17;
				font-size: var(--clamp-size);
				padding: 0 13px 20px;
			}
			&-image{
				display: block;
			}
		}
		&-btn{
			display: block;
			margin: 0 auto;
			margin-top: 71px;
		}
	}
}