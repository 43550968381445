@use "mixin" as mixin;
@use "config/variables" as v;

.company {
  &__mv {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__greeting {
    &-heading {
      margin-top: 72px;
      @include mixin.sp {
        padding-top: 28px;
      }
    }
    &-content {
      padding-top: 87px;
      @include mixin.sp {
        padding-top: 33.6px;
      }
    }
    &-paragraph {
      --min-size: 16;
      --max-size: 18;
      font-size: var(--clamp-size);
      line-height: calc(26 / 15);
      &--text_right {
        display: block;
        text-align: right;
      }
    }
  }
  &__concept {
    margin-top: 52px;
    &-heading {
      padding-top: 50px;
      text-align: center;
    }
    &-contents {
      padding: 34px 0 55px 213px;
      @include mixin.pc-small {
        padding-left: 50px;
      }
      @include mixin.tablet {
        padding: 49px 28px 51px 16px;
      }
    }
    &-title {
      --min-size: 18;
      --max-size: 22;
      font-size: var(--clamp-size);
      &:not(:first-child) {
        padding-top: 56px;
        @include mixin.sp {
          padding-top: 43px;
        }
      }
    }
    &-content {
      --min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
      padding-top: 18px;
      line-height: calc(30 / 17);
      @include mixin.sp {
        line-height: calc(27 / 15);
      }
    }
  }
  &__through {
    margin-top: 112px;
    @include mixin.sp {
      margin-top: 60px;
    }
    &-heading {
      text-align: center;
    }
    &-content {
      padding-top: 43px;
      --min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
      line-height: calc(30 / 17);
      @include mixin.sp {
        padding-top: 32px;
        line-height: calc(28 / 15);
      }
    }
    // &-persons {
    //   margin-top: 78px;
    //   @include mixin.tablet {
    //     margin-top: 55px;
    //   }
    // }
    &-person {
      display: flex;
      max-width: 900px;
      margin: 64px auto 0;
      justify-content: space-between;
      @include mixin.sp {
        flex-direction: column;
      }
      dl {
        display: block;
        dt {
          display: inline-block;
          font-weight: initial;
        }
        dd {
          margin-left: 16px;
          display: inline-block;
        }
      }
      &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 200px;
          height: auto;
          display: flex;
          @include mixin.sp {
            width: 192px;
          }
        }
      }
      &-txt {
        width: calc(100% - 200px - 52px);
        @include mixin.sp {
          width: 100%;
          margin-top: 24px;
        }
      }
      @include mixin.tablet {
      }
    }
  }
  &__information {
    margin-top: 153px;
    @include mixin.sp {
      margin-top: 89px;
    }
    &-heading {
      text-align: center;
    }
    &-contents {
      border-collapse: separate;
      border-spacing: 1px;
      width: 100%;
      @include mixin.sp {
        margin-top: 55px;
      }
    }
    &-content {
      vertical-align: middle;
      &:not(:first-child) th {
        @include mixin.sp {
          padding-top: 35px;
        }
      }
    }
    &-title {
      font-weight: 500;
      width: 18%;
      --min-size: 15;
      --max-size: 20;
      font-size: var(--clamp-size);
      padding-top: 41px;
      padding-bottom: 41px;
      padding-left: 21px;
      text-align: left;
      vertical-align: middle;
      border-bottom: 2px dotted #808080;
      @include mixin.sp {
        width: 100%;
        display: block;
        padding-top: initial;
        padding-bottom: initial;
        border-bottom-width: 0;
      }
    }
    &-text {
      --min-size: 15;
      --max-size: 20;
      font-size: var(--clamp-size);
      line-height: calc(32 / 20);
      padding-top: 41px;
      padding-bottom: 41px;
      vertical-align: middle;
      border-bottom: 2px dotted #808080;
      @include mixin.sp {
        display: block;
        padding: 27px 0 27px 21px;
      }
    }
    &-location {
      &:not(:first-child) {
        padding-top: 47px;
      }
    }
    &-address {
      padding-left: 130px;
      @include mixin.sp {
        padding-left: initial;
      }
    }
    &-permit {
      &:not(:first-child) {
        padding-top: 44px;
      }
    }
    &-image {
      display: inline-block;
      padding-top: 78px;
    }
  }
  &__access {
    margin-top: 153px;
    margin-bottom: 133px;
    @include mixin.sp {
      margin-top: 106px;
    }
    &-heading {
      text-align: center;
    }
    &-map {
      margin-top: 53px;
      position: relative;
      width: 100%;
      padding-top: 49.2511013%;
      @include mixin.sp {
        margin-top: 25px;
        padding-top: 67.2566372%;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
