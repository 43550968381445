@use "mixin" as mixin;
@use "config/variables" as v;

.privacy{
	margin-top: 50px;
	&-heading02{
		@include mixin.sp{
			padding: 0 50px;
			&::before, &::after{
				width: 40px;
			}
		}
	}
	&-lists{
		margin-top: 70px;
		margin-bottom: 100px;
	}
	&-list{
		margin-top: 30px;
		&:first-of-type{
			margin-top: 0;
		}
		&-item{
			--min-size: 15;
      --max-size: 17;
      font-size: var(--clamp-size);
		}
	}
}