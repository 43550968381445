@use "mixin" as mixin;
@use "config/variables" as variables;

.breadcrumb {
  --min-size: 12;
  --max-size: 13;
  font-size: var(--clamp-size);
  padding-left: calc((32px / 1368px) * 100vw);
  @include mixin.sp {
    padding-left: initial;
  }
  &-outer {
    max-width: 1368px;
    margin: 18px auto 0;
    padding: 0 20px;
    width: 100%;
    @include mixin.sp {
      padding: 0 18px;
      margin-top: 12px;
    }
  }
}

.common {
  &__inner {
    max-width: 1176px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    &--narrow {
      max-width: 1076px;
    }
    &-outer {
      text-align: center;
      margin-top: 72px;
    }
    &--narrowest {
      max-width: 1000px;
    }
    &--intro {
      max-width: 100%;
    }
    &__pc {
      display: block;
      @include mixin.sp {
        display: none;
      }
    }
    &__sp {
      display: none;
      @include mixin.sp {
        display: block;
      }
    }
    &--company-text {
      max-width: 883px;
    }
  }
  &__btn {
    border: 1px solid #448350;
    --min-size: 14;
    --max-size: 16;
    font-size: var(--clamp-size);
    line-height: 1;
    padding: 18px 0;
    text-align: center;
    max-width: 280px;
    display: inline-block;
    width: 100%;
    background: #fff;
    &:hover {
      transition: all 0.3s ease-in;
      color: #fff;
      background: #000;
    }
    &-outer {
      text-align: center;
      margin-top: 72px;
      @include mixin.sp {
        margin-top: 44px;
      }
    }
    &--search {
      --min-size: 15;
      --max-size: 16;
      background: #448350;
      color: #fff;
      display: inline-block;
      max-width: 160px;
      padding: 14px 18px;
      text-align: center;
    }
  }
  &__bg-gray {
    background: #f2f2f2;
  }
  &__pc {
    display: block;
    @include mixin.sp {
      display: none;
    }
  }
  &__sp {
    display: none;
    @include mixin.sp {
      display: block;
    }
  }
  &__pc-br {
    display: block;
    @include mixin.sp {
      display: none;
    }
  }
  &__sp {
    display: none;
    @include mixin.sp {
      display: block;
    }
  }
}
